<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          
          <CRow>
            <CCol col="6" class="text-left"><h4>Admin Transactions</h4></CCol>
            <CCol col="6" class="text-right">
                  <CButton v-on:click="showFormPasswd()" color="success">Download</CButton>                
            </CCol> 
          </CRow>

        </CCardHeader>
        
        <CCardBody>  
         
          <CRow>
            <CCol col="12" class="text-left">                   
               <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
            </CCol> 
         </CRow> 

          <div class="text-center">
            <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
          </div>

                     <b-card-group deck class="mb-3" style="width:50%" v-if="formPasswd">  
                        <b-card border-variant="dark" header="Protected Excel Password" align="center">
                        <b-card-text>
                            <b-form-input style="text-align: center" v-model="password" size="md" :readonly="true"></b-form-input>
                        </b-card-text>                                                                      
                        <b-button-group>
                           <b-button v-on:click="actionCancel()">Cancel</b-button>
                           <b-button variant="success" v-on:click="generateWorkbook()">Download</b-button>
                        </b-button-group>                                   
                        </b-card>
                     </b-card-group>
        
         
        <b-card-group>
          <b-card  header="" header-tag="header" footer-tag="footer" title="">  
               <b-row>
                <b-col sm="3"> 
                     <CInput
                        label="From Date"
                        type="date"
                        vertical
                        v-model="fromDate" 
                        @input="startDateOnChange()" 
                      />                
                </b-col>
                <b-col sm="3">  
                    <CInput
                        label="To Date"
                        type="date"
                        vertical
                        v-model="toDate"
                        @input="endDateOnChange()"
                      />                       
                </b-col>
                <b-col sm="3">
                    <div class="form-group">
                        <b-row>
                        <b-col sm="6">
                        <label for="input-small" >Status</label>
                        </b-col>
                        <b-col sm="6">
                        <b-form-checkbox
                          id="cbstatus"
                          v-model="cbstatus"
                          name="cbstatus"                          
                          @change="onModifyClick"
                        >
                         All
                        </b-form-checkbox>
                        </b-col>
                        </b-row>
                          <multiselect v-bind="attrMSelect" @select="toggleStsSelected" @remove="toggleStsUnSelect" v-model="defOptSts" :options="optStatus" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"  label="name" track-by="name" :preselect-first="true">
                            <template slot="selection1" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                          </multiselect> 
                    </div>
                </b-col>
                <b-col sm="3">
                    <div class="form-group">
                        <label for="input-small" >Currency</label>
                        <multiselect @select="toggleCurrSelected" @remove="toggleCurrUnSelect" v-model="defOptCurr" :options="optCurrency" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick some" label="name" track-by="name" :preselect-first="true">
                            <template slot="selection2" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                        </multiselect>
                    </div>
                </b-col>
               </b-row>

               <b-row>
                <b-col sm="4">
                     <div class="form-group">                     
                     <CInput
                        v-bind="attrsFrom"
                        type="number"
                        min='0'
                        @change="amtFromChange" 
                        v-model="amountFrom" 
                        :id="amountFrom"
                        label="Amount From"                        
                        required                        
                      />
                      </div>
                </b-col>
                <b-col sm="4">
                     <div class="form-group">
                        <CInput
                           v-bind="attrsTo"
                           type="number"
                           min='0'
                           @change="amtToChange" 
                           v-model="amountTo" 
                           :id="amountTo"                       
                           label="Amount To"
                           required                       
                        />
                     </div>                                          
                </b-col>
                 <b-col sm="4">
                     <div class="form-group">
                        <label for="input-small" >Type</label>
                        <b-form-select @change="typeChange" v-model="defoptType" :options="optType"></b-form-select>
                     </div>
                </b-col>
                
                <!--b-col sm="2">
                    <CInput                        
                        type="number"
                        min='1'
                        max='24'
                        @change="timeRangeChange" 
                        v-model="timeRange" 
                        :id="timeRange"                       
                        label="Time Range"
                        description="1 - 24 Hours"
                        required 
                        was-validated                      
                        />                   
                </b-col>
                <b-col sm="2">
                     <div class="form-group">
                        <label for="input-small" >Start Time</label> <br/-->                       
                        <!--vue-timepicker @change="timeChange" v-model="time" format="HH:mm:ss"></vue-timepicker>
                        <vue-timepicker @change="timeChange" v-model="time" format="HH:mm" :minute-interval="10"></vue-timepicker>
                     </div>                     
                </b-col-->
               </b-row>

               <b-row>  
                  <b-col sm="12">                
                     <div class="form-group">
                        <label for="input-small" >Search</label>
                        <b-form-input v-model="filter" placeholder="Type to Search" :id="search"></b-form-input>                   

                     </div>
                  </b-col>
               </b-row>
           </b-card>
        </b-card-group><br/>  
        
        <div class="table-responsive table">   
      
           <b-table striped hover :items="items" :fields="fields" sort-by="transactionDate" sort-desc="true" :current-page="currentPage" :per-page="perPage" :filter="filter">

              <template v-slot:cell(transactionDate)="{ item }"> 
                {{ item.transactionDate | setLocalDate }}
              </template>

           </b-table>
         </div><br/> 

        <div>
          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>       
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>      
        </div>   
        </CCardBody>
        
      </CCard>
    </CCol>
  </CRow>
</template>


<script>
import AuthService from '@/api/AuthService.js';
import ReportService from '@/api/ReportService.js';
import DateRangePicker from 'vue2-daterange-picker';
import Multiselect from 'vue-multiselect';
import VueTimepicker from 'vue2-timepicker';
import moment from 'moment';

 export default {
    name: "transaction", 
    components: {
       Multiselect,
       VueTimepicker      
    },     
    data() { var date = new Date();

      return {
          spinner:false,
          columns : [],
          data : [],
          time:'',
          timeRange:'',
          defOptSts:'',
          defOptCurr:'',
          cbstatus:false,
          attrsFrom: {
            description : ""            
          },
          attrsTo: {
            description : ""            
          },
          attrMSelect:{
            placeholder : ""
          },
          //fromDate:null,
          fromDate: new Date(date.getFullYear(), date.getMonth(), 2).toISOString().substr(0, 10),
          //toDate:null, 
          toDate: new Date().toISOString().substr(0, 10),         
          defoptType:null,
          optType:[
              { value: 'SYSTEM', text: 'SYSTEM'},
              { value: 'TOP_UP', text: 'TOP_UP'},
              { value: null, text: 'ALL'}
          ],        
          optStatus: [
              { name: 'COMPLETED'},
              { name: 'CANCELED'},
              { name: 'REFUNDED'}             
          ],
          baseOptStatus:[],           
          optCurrency: [
                { name: 'SGD' },
                { name: 'IDR' },
                { name: 'USD' }                
          ],
          items:[], 
          type:[],
          status: [],
          currency:[],
          amountFrom:null,
          amountTo:null,  
          search:null,        
          fields: [
            {
               name : 'transactionId',
               key: 'transactionId',
               label : 'Transaction ID',
               sortable: true
            }, 
            {
               name : 'transactionDate',
               key: 'transactionDate',
               label : 'Transaction Date',
               sortable: true
            }, 
            /*{
               name : 'wallet',
               key: 'wallet',
               label : 'Wallet',
               sortable: true
            }, */
            {
               name : 'sender',
               key: 'sender',
               label : 'Received From',
               sortable: true
            }, 
            {
               name : 'senderPhone',
               key: 'senderPhone.number',
               label : 'Received Phone No.',
               sortable: true
            }, 
            {
               name : 'beneficiary',
               key: 'beneficiary',
               label : 'Sent To',
               sortable: true
            },
            {
               name : 'beneficiaryPhone',
               key: 'beneficiaryPhone.number',
               label : 'Sent Phone No.',
               sortable: true
            }, 
            {
               name : 'currency',
               key: 'currency',
               label : 'Currency',
               sortable: true
            }, 
            {
               name : 'amount',
               key: 'amount',
               label : 'Amount($)',
               sortable: true
            }, 
            {
               name : 'movementType',
               key: 'movementType',
               label : 'Type',
               sortable: true  
            }, 
            {               
               name : 'adminUser',
               key: 'adminUser',
               label : 'Admin Name',
               sortable: true
            },
            {
               name : 'status',
               key: 'status',
               label : 'Status',
               sortable: true
            },
            {
               name : 'reason',
               key: 'reason',
               label : 'Reason',
               sortable: true
            }
          ],
          currentPage: 1,
          perPage: 10,
          filter: null,  
          formPasswd:false,
          filename:"admin-transaction.xlsx",
          password:"",
          seen:false,
          msg :'',
          color:''       
         };    
   }, 

   filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },
        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           value = value.split(".") 
           return value[0].replace("T"," ")            
        },
        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        },
        getGeneraldate(value){
            var dt  = new Date(value);
            var dt2 = dt.toISOString();
            var temp = dt2.split(".");
            value = temp[0].replace("T"," ");
            return value;
        },
        setLocalDate(value){
           if(!value) return ''
           return value.toLocaleString("sv-SE");
        }
   },

    created () {        
        //this.retrieveTransac();
        this.getConfigList();  
        this.setColumns(); 
        this.setDefaultPasswd();       
    },
    methods: { 

      showFormPasswd(){
         this.formPasswd = true;
      },

      actionCancel(){
          this.formPasswd = false;
      },
       
      setDefaultPasswd(){
        var today = moment().format('DDMMYYYYHHmmss');
        var user  = this.$store.getters.getUser; 
        this.password = user+today;
      },
      
      generateWorkbook(){

         this.formPasswd = false; this.spinner=true;
         var cols  = this.columns; var rows  = this.data;        
         var heads = []; var temp =[]; var data=[];
         let params = {};   var colkey =[];      

          for (var key in cols) {
             var col = cols[key]; heads.push(col.label);
             colkey.push(col.field);
          } 
          data.push(heads);  var jum = heads.length;          

          for (var key2 in rows) { var row = rows[key2]; 
              var init = Array(jum).fill(null);
              for(var key4 in colkey){
                for (var key3 in row) { 
                  var val  = row[key3]; 
                  if(key3==="transactionDate"){
                    var val = new Date(val).toISOString().substr(0, 19).replace("T"," ");
                  }                
                  var ckey = colkey[key4]; 
                  if(key3===ckey){  init[key4] = val; }              
                }
              }
              data.push(init); 
          } 

         params['password'] = this.password;
         params['data'] = data;   //console.log(params); 
         this.getReportExcel(params);
      },

      getReportExcel: function(params) {
        ReportService.getReportExcel(params).then(resp => {             
         try {  
            if(resp.status==200){
               var filename = this.filename;
               const link = document.createElement('a');    
               link.href = window.URL.createObjectURL(
                   new Blob([resp.data])
               );   
               link.setAttribute('download', filename);
               document.body.appendChild(link);
               link.click(); this.spinner = false; 
            } else {
              this.msg   = 'Sorry, Generated Excel Failed!'; 
              this.color = 'warning'; this.seen  = true;
              this.spinner = false; 
              setTimeout( () =>  this.seen=false , 5000);
            }
           }
         catch(err) {            
            this.msg   = 'Sorry, Generated Excel Failed!'; 
            this.color = 'warning'; this.seen  = true;
            this.spinner = false; 
            setTimeout( () =>  this.seen=false , 5000);
         } 

        }, error => { 
           this.spinner = false;            
        });
      }, 

      setColumns(){
        var fields = this.fields; let row = {}; var cols = [];
        for (var key in fields) {           
           row['label'] = fields[key].label;
           row['field'] = fields[key].key;
           var temp = JSON.stringify(row); cols.push(temp);
        }        

        for (var key2 in cols) {               
           var row2 = JSON.parse(cols[key2]); this.columns.push(row2);
        }
      },

      onModifyClick(e) { 
        if(e){
            //console.log('checked')
            this.attrMSelect.placeholder="ALL";
            this.defOptSts='';
            this.optStatus=[]; 
            this.status =[]; 
            this.retrieveTransac();          
        } else {
            //console.log('unchecked')
            this.attrMSelect.placeholder="Pick Some";
            this.optStatus = this.baseOptStatus;
            this.retrieveTransac();
        }

      },

      getConfigList: function() { 
          AuthService.getConfigList().then(resp => {            

            var currSrc=[]; 
            var arrcurrency = resp.currency;
            for (const item of arrcurrency){ currSrc.push({ name : item}); }
            this.optCurrency = currSrc;

            var typeSrc=[];
            var arrTypes = resp.transactionType;
            for (const item2 of arrTypes) {                    
                if(item2!=="ADMIN") { typeSrc.push({ value: item2 , text: item2 }); }
            }
            typeSrc.push({ value: null  , text: 'ALL' });
            //this.optType = typeSrc;

            var mvStsSrc=[];
            var arrMvSts = resp.movementStatus;
            for (const item3 of arrMvSts){ mvStsSrc.push({ name : item3 }); }
            this.optStatus = mvStsSrc;
            this.baseOptStatus = mvStsSrc;

           }, error => {
            console.log('error');    
          });
      },     

      getRequestParams(params) {  

        var term = this.time; 
        var re = new RegExp("^[0-9:]*$");      

        params["userId"] = null;
        params["currency"] = this.currency;
        params["searchString"] = null;
        params["movementType"] = "ADMIN";
        params["fromDate"] = this.fromDate+"T00:00:00";
        params["toDate"] = this.toDate+"T23:59:59";
        params["fromRange"] = this.amountFrom;
        params["toRange"] = this.amountTo;
        params["movementStatus"] = this.status;
        params["startTime"] = null; //(re.test(term)) ? term : null;
        params["timeRange"] = null; //this.timeRange;        

        return params;        
      },

      retrieveTransac(){ 
            this.spinner=true;    let params = {};  let items = []; 
            this.getRequestParams(params); 

            AuthService.getUserTransact(params).then((resp) => {  //console.log(resp);                  
                  for (var key in resp) {
                     var temp = resp[key];  
                     temp.transactionDate = new Date(temp.transactionDate);

                     if(this.defoptType === "SYSTEM"){ 
                        if(temp.systemTransactionType == 'SYSTEM'){
                           temp.movementType   ="SYSTEM";  items.push(temp); 
                        }
                     } else if (this.defoptType === "TOP_UP"){
                        if(temp.systemTransactionType == 'TOPUP'){
                           temp.movementType   ="TOP_UP";  items.push(temp); 
                        }
                     } else {
                        items.push(temp); 
                     }
                  }
                  this.items = items;
                  this.data  = items; this.spinner = false;
            }).catch((e) => { console.log(e); }); 
      },  

      amtFromChange: function(){ 
          if(this.amountTo){
            this.attrsFrom.description= "";
            this.attrsTo.description= "";     
            this.retrieveTransac();             
          } else { this.attrsTo.description = "Amount To Required !" }
      },

     amtToChange: function () {
         if(this.amountFrom){
            this.attrsFrom.description= "";
            this.attrsTo.description= "";
            this.retrieveTransac();
         } else { this.attrsFrom.description = "Amount From Required !"  }
     },

     timeChange(){     
         var term = this.time; //console.log(this.time);
         var re = new RegExp("^[0-9:]*$");
         if (re.test(term)) {
           this.retrieveTransac(); 
         } 
     },

     timeRangeChange(){
        this.retrieveTransac(); 
     },

     searchChange(){
         this.retrieveTransac();
     },

     typeChange(){
         this.retrieveTransac();
     },

     startDateOnChange(){
         this.retrieveTransac();
     },  

     endDateOnChange(){
         this.retrieveTransac();
     },      

     toggleStsSelected(value) { 
         if(!this.status.includes(value.name)){
             this.status.push(value.name);  
             this.retrieveTransac();
         }
        
     },

     toggleStsUnSelect(value, id){
         for( var i = 0; i < this.status.length; i++){     
              if ( this.status[i] === value.name) {           
                  this.status.splice(i, 1); 
              }          
          } 
         
          this.retrieveTransac();      
     }, 

     toggleCurrSelected(value) { 
         if(!this.currency.includes(value.name)){                  
            this.currency.push(value.name);
            this.retrieveTransac();
         }
     },

     toggleCurrUnSelect(value, id){

         for( var i = 0; i < this.currency.length; i++){     
              if ( this.currency[i] === value.name) {           
                    //console.log(this.currency);
                    delete this.currency[i];                   
              }          
          } 
          
         
         this.retrieveTransac();      
     },   

    mounted() {
        this.retrieveTransac();  
    },

    },

  }; 

</script>

<style>
    .table{ overflow-y:scroll; }
</style>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-timepicker/dist/VueTimepicker.css"></style>
